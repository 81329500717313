import React from 'react'
import { Router } from '@reach/router'
import Details from '../components/Details'
import Profile from '../Screens/Profile'
import Login from '../Screens/Login'
import Sso from '../Screens/Sso'
import PrivateRoute from '../components/PrivateRoute'


function RouterComponent({ children }) {
  // Shorthand of <React.Fragment>{children}</React.Fragment>
  return <>{children}</>;
}

const App = () => (
    <Router primary={false} component={RouterComponent}>
      <PrivateRoute path="/app/details" component={Details} />
      <PrivateRoute path="/app/profile" component={Profile} />
      <PrivateRoute path="/app/sso" component={Sso} />
      <Login path="/app/login" />
    </Router>
)

export default App
