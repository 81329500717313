import { Redirect } from "@reach/router";
import React from "react";
import { isLoggedIn } from "../utils/auth";

class PrivateRoute extends React.Component {

  render() {
    const { component: Component, location, ...rest } = this.props
        
    if (!isLoggedIn()) {
      return <Redirect to={`/app/login?redirect=${location.pathname}${location.search}`} noThrow />;
    }
    return <Component {...rest} />
  }
}

export default PrivateRoute