import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import * as Card from '../components/Styled/Card'
import LoginForm from '../Containers/LoginForm'
import { Title, BodyText } from '../components/Styled/Type'
import PlatformBackground from '../assets/images/platoform.jpg'
import * as LogoBar from '../components/Styled/LogoBar'
import LogoBarComponent from '../components/Elements/LogoBar'
import beta from '../assets/images/beta.png'
import { Box } from 'grommet'
import Layout from '../components/Global/Layout'
import Seo from '../components/Global/Seo'
const LoginScreen = () => {
  return (
    <Layout>
      <Seo />
      <Box pad={{ top: '150px' }} fill={true}>
        <Box flex={'grow'}>
          <Row>
            <Col md={6}>
              <Card.Container style={{ maxWidth: 500, margin: '0 auto' }}>
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <img src={beta} />
                    </Col>
                  </Row>
                  <Title small>Login</Title>
                  <BodyText>
                    You must have a Joto account setup to login. Please register
                    in the official app if you don't have an account.
                  </BodyText>
                  <LoginForm />
                </Card.Body>
              </Card.Container>
            </Col>
            <Col md={6}>
              <img src={PlatformBackground} />
            </Col>
          </Row>
        </Box>
        <LogoBar.Bar>
          <Container>
            <LogoBarComponent />
          </Container>
        </LogoBar.Bar>
      </Box>
    </Layout>
  )
}

export default LoginScreen
