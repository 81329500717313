import { Auth, Storage } from "aws-amplify";
import React, { useEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import { useDispatch, useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { Col, Row } from "reactstrap";
import styled from "styled-components/macro";
import {
  BodyText, Bullet,

  Link, SubTitle
} from "../components/Styled/Type";
import { imgixurl } from "../config";
import { profileRequest } from "../State/profile/actions";

const Profile = styled.div`
  padding-top: ${(props) => props.theme.spacing.m}vw;
  padding-bottom: ${(props) => props.theme.spacing.l}vw;
  border-bottom: 1px solid #e3e3e3;
`;

const ProfileInfo = () => {
  const [identityId, setIdentityId] = useState(false);
  const [modal, setModal] = useState(false);
  const formRef = useRef();
  const dispatch = useDispatch();
  const {
    firstname,
    lastname,
    handle,
    // socials,
    image,
    website,
    bio,
  } = useSelector((state) => state.profile.info);

  const getCredentials = async () => {
    const credentials = await Auth.currentCredentials();
    return encodeURIComponent(credentials.identityId);
  };

  useEffect(() => {
    // dispatch(profileRequest());
    getCredentials().then((response) => {
      setIdentityId(response);
    });
  }, []);

  // const user = useSelector((state) => state.authentication.user.username);

  // const onConfirm = () => {
  //   formRef.current.dispatchEvent(new Event("submit", { cancelable: true }));
  // };

  // const onSubmit = (data) => {
  //   Storage.put(`profile/${data.imagefile[0].name}`, data.imagefile[0], {
  //     level: "protected",
  //     contentType: data.imagefile[0].type,
  //   })
  //     .then((result) => {
  //       // console.log("UPLOAD SUCCESS");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const toggle = () => {
  //   setModal(() => !modal);
  // };

  return (
    <Profile>
      {firstname ? (
        <Row>
          <Col md={{ size: 3, offset: 0 }}>
            <div
              style={{
                alignItems: "center",
                flexDirection: "column",
                display: "flex",
              }}
            >
              {identityId && (
                <div style={{width: 160, height: 160, borderRadius: "100%",borderStyle: "solid", borderWidth: 1, borderColor: "#e3e3e3", overflow: "hidden" }}>
                  <Avatar
                  round
                  size={160}
                  src={`${imgixurl}/protected/${identityId}/${image}`}
                />
                </div>
                
              )}
              {/* <Modal
                toggle={toggle}
                onConfirm={onConfirm}
                modal={modal}
                content={
                  <ProfileForm
                    identityId={identityId}
                    ref={formRef}
                    onSubmit={onSubmit}
                  />
                }
                buttonLabel="Edit Profile"
              /> */}
            </div>
          </Col>
          <Col md={{ size: 8 }}>
            <SubTitle style={{marginTop: 10}} margin={"1rem"}>{`@${handle}`}</SubTitle>
            <Bullet margin={"0.5rem"}>{`${firstname} ${lastname}`}</Bullet>
            <BodyText>{bio || "Not set"}</BodyText>
            <Link href={website} target={"_blank"}>
              {website}
            </Link>
          </Col>
        </Row>
      ) : (
        <PulseLoader />
      )}
    </Profile>
  );
};

export default ProfileInfo;
