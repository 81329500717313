import React, { useEffect, useRef, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Body } from '../components/Styled/Type'
import { imgixurl } from '../config'
import { userJotsRequest } from '../State/profile/actions'

import {
  Box,
  Card,
  Grid,
  Image,
  ResponsiveContext,
} from 'grommet'

const UserJots = () => {
  const jots = useSelector(state => state.profile.jots)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userJotsRequest({}))
  }, [])

  const size = useContext(ResponsiveContext)

  return (
    <Box pad={{vertical: "xlarge"}}>
      <Grid fill={false} columns={size !== 'xsmall' ? 'small' : '100%'} gap="large">
        {jots &&
          jots.allIds.map((id, index) => {
            let { title, svgFilename, userId } = jots.byId[id];
            let highres = true

            return (
              <Box key={index}>
              <Card pad={{vertical: "large", horizontal: "medium" }} key={index}>
                <Image
                  fit="cover"
                  src={`${imgixurl}/protected/${encodeURIComponent(
                    userId
                  )}/jots/${svgFilename}?w=${highres ? 800 : 200}`}
                />
              </Card>
              <Box pad={{vertical: "small"}}>
              <Body>{title}</Body>
              </Box>
              </Box>
            )
          })}
      </Grid>
    </Box>
  )
}

export default UserJots
