import React from "react";
import { Container } from "reactstrap";
import BodyWrapper from "../components/Styled/BodyWrapper";
import UserProfile from "../Containers/UserProfile";
import UserJots from "../Containers/UserJots";

const ProfilePage = ({
  banner,
  posts,
  cartTotal,
  prismicCtx,
  openCart,
  ...rest
}) => {
  return (
    <BodyWrapper>
      <Container>
        <UserProfile />
      </Container>
      <Container>
        <UserJots />
      </Container>
    </BodyWrapper>
  );
};

export default ProfilePage;
