// Helper styles for demo

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Form, FormGroup } from "reactstrap";
import Button from "../components/Styled/BuyButton";
import { ErrorContainer, StyledInput } from "../components/Styled/Form";
import { loginErrorClear } from "../State/authentication/actions";
import { cognitoLogin } from "../State/authentication/operations";
import { withLocation } from "../helpers";
import queryString from "query-string";

const LoginForm = ({location}) => {
  const { error } = useSelector((state) => state.authentication);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = async (values) => {
    setLoading(true);
    const {redirect, ...query} = queryString.parse(location.search);
    dispatch(cognitoLogin(values.email, values.password, redirect, queryString.stringify(query)));
  };

  const handleOwnerChange = () => {
    error && loginErrorClear();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <StyledInput
          type="text"
          placeholder="Email"
          name="email"
          onChange={handleOwnerChange}
          ref={register({
            required: "Required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i,
              message: "invalid email address",
            },
          })}
        />
        <ErrorContainer>{errors.email && errors.email.message}</ErrorContainer>
      </FormGroup>
      <FormGroup>
        <StyledInput
          onChange={handleOwnerChange}
          type="password"
          placeholder="Password"
          name="password"
          ref={register({
            required: "Required",
          })}
        />
        <ErrorContainer>
          {errors.password && errors.password.message}
        </ErrorContainer>
      </FormGroup>
      <Button type="submit" style={{ minWidth: 200 }} color={"third"}>
        {loading ? (
          <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
        ) : (
          "Login"
        )}
      </Button>

      {error && <h1>{error}</h1>}
    </Form>
  );
};

export default withLocation(LoginForm);
